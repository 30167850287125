<script lang="ts">
	import '../styles/fonts.scss';
	import '../app.scss';

	import SiteHeader from '$components/SiteHeader.svelte';
	import SiteFooter from '$components/SiteFooter.svelte';
	import NavigationProgressBar from '$components/NavigationProgressBar.svelte';

	import SanityPreviewVisualEditing from '$components/SanityPreviewVisualEditing.svelte';
	import { previewModeActivated } from '$utils/sanityUtils';

	import { gtagId } from '$utils/googleAnalyticsProxyPrefix';
	// Would be nice to proxy this at some point instead
	const gtagScriptSource = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
</script>

{#if previewModeActivated}
	<!-- Sanity CMS visual editing config -->
	<SanityPreviewVisualEditing />
{/if}

<svelte:head>
	<link
		rel="preload"
		href="/fonts/ABCFavoritVariable/ABCFavoritVariableEdu.woff2"
		as="font"
		type="font/woff2"
		crossorigin="anonymous"
	/>

	<!-- Google tag (gtag.js) -->
	<script async src={gtagScriptSource}></script>
	<script>
		console.log('gtag init');
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		gtag('js', new Date());

		// https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#default-consent
		gtag('consent', 'default', {
			ad_storage: 'denied',
			ad_user_data: 'denied',
			ad_personalization: 'denied',
			analytics_storage: 'denied'
		});

		gtag('config', 'G-2CEQMVFDFR');
	</script>
</svelte:head>

<NavigationProgressBar />

<SiteHeader />

<slot />

<SiteFooter />

<style>
	:global(:root) {
		--background-color: var(--main-bg-color);
		--main-text-color: var(--main-body-grey);
		--header-link-hover: rgb(var(--blue));
		--header-bottom-line-color: rgb(var(--main-body-grey));
	}

	:global(body) {
		background-color: rgb(var(--background-color));
		color: rgb(var(--main-text-color));
	}
</style>
